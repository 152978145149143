import React from "react";
import {
  Header,
  Tab,
  Loader,
  Icon,
  Modal,
  TextArea,
  Label,
} from "semantic-ui-react";
import QuickView from "../../../components/shared/quickview/QuickView";
import { defaultValues } from "../../../app_constants";
import TableWrapper from "../../../components/shared/react-table/TableWrapper";
import {
  createTableHeaderObject,
  deep_Compare,
  getIconForResource,
  copyToClipboard,
  toastError,
} from "../../../app_shared_functions";

import FetchAPI from "../../../api/FetchAPI";
import { toast } from "react-toastify";
import { filterActionColumn } from "../../../shared-functions/authenticate";
import CircularButton from "../../../components/shared/circularbutton/CircularButton";

class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined,
    };
  }

  componentDidMount() {
    this.getPaneData();
    this.observeChanges = setInterval(() => this.getPaneData(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.observeChanges);
  }

  getPaneData = () => {
    const { stack } = this.props;
    FetchAPI.Orchestration.Stacks.getProperty({
      stack,
      property: "resources",
    })
      .then((res) => this.checkAndUpdateState(res.data))
      .catch(() => this.setState({ data: null }));
  };

  mapDataToRequiredProperties = (data) => {
    return data.map((element) => ({
      logical_resource_id: element.logical_resource_id,
      physical_resource_id: element.physical_resource_id,
      resource_name: element.resource_name,
      resource_status: element.resource_status,
      resource_status_reason: element.resource_status_reason,
      resource_type: element.resource_type,
    }));
  };

  checkAndUpdateState = (fetchedData) => {
    if (!this.state.data) {
      this.setState({ data: fetchedData });
    }

    const oldData = this.mapDataToRequiredProperties(this.state.data);
    const newData = this.mapDataToRequiredProperties(fetchedData);

    if (!deep_Compare(oldData, newData)) {
      setTimeout(() => {
        this.setState({ data: newData });
      }, 1000);
    }
  };

  displayResult = (type, text, index) => {
    const { data } = this.state;

    if (type === "success") {
      toast.success(text);
    } else {
      toastError(text);
    }

    data[index].__status__ = text;
    data[index].__type__ = type;

    this.setState({ data }, () => {
      setTimeout(() => {
        const { data } = this.state;
        data[index].__status__ = "";
        data[index].__type__ = "";
        this.setState({ data });
      }, 2500);
    });
  };

  requestSignal = (resource, index) => {
    const { stack } = this.props;

    this.setStatusToLoading(index);
    FetchAPI.Orchestration.Stacks.requestSignal({
      stack,
      resource,
    })
      .then((res) =>
        this.displayResult("success", "Signal request sent", index),
      )
      .catch((err) =>
        this.displayResult(
          "error",
          err?.response?.data?.error?.message || "Signal request failed!",
          index,
        ),
      );
  };

  toggleResourceHealth = (resource, index, flag) => {
    const { stack } = this.props;

    this.setStatusToLoading(index);

    const objectToSend = {
      patch: {
        mark_unhealthy: flag,
      },
    };

    FetchAPI.Orchestration.Stacks.toggleResourceHealth({
      stack,
      resource,
      objectToSend,
    })
      .then((res) =>
        this.displayResult("success", "Resource set as unhealthy", index),
      )
      .catch((err) =>
        this.displayResult(
          "error",
          err?.response?.data?.error?.message ||
            "Toggling resource health failed!",
          index,
        ),
      );
  };

  displayMetaData = (metaData, index) => {
    this.resetStatus(index);
    this.setState({
      activeIndex: index,
      metaData,
    });
    this.toggleModal();
  };

  toggleModal = () => this.setState({ modalStatus: !this.state.modalStatus });

  getMetaData = (resource, index) => {
    const { stack } = this.props;

    this.setStatusToLoading(index);
    FetchAPI.Orchestration.Stacks.getMetaData({
      stack,
      resource,
    })
      .then((res) => this.displayMetaData(res.data, index))
      .catch((err) =>
        this.displayResult(
          "error",
          err?.response?.data?.error?.message || "Fetching metadata failed!",
          index,
        ),
      );
  };

  setStatusToLoading = (index) => {
    const { data } = this.state;
    data[index].__status__ = "Requesting...";
    this.setState({ data });
  };

  resetStatus = (index) => {
    const { data } = this.state;
    data[index].__status__ = "";
    this.setState({ data });
  };

  renderActions = (resource, index) => {
    if (resource.__status__ === "Requesting...") {
      return (
        <div>
          <Icon loading name="spinner" className="margin-right" />
          Requesting...
        </div>
      );
    } else if (resource.__status__) {
      return (
        <div className={resource.__type__ === "error" ? "color-red" : ""}>
          {resource.__status__}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <CircularButton
            onClick={() => this.requestSignal(resource, index)}
            className={`button button--circular margin-right-half `}
            icon="bolt"
            popupContent="Signal Resource"
          />
          {(resource?.resource_status || "")
            .toLowerCase()
            .includes("failed") ? (
            <CircularButton
              onClick={() => this.toggleResourceHealth(resource, index, false)}
              className={`button button--circular margin-right-half `}
              icon="times"
              popupContent={
                <p>
                  Resource status is : {resource?.resource_status || "Unknown"}
                  <br />
                  Click to{" "}
                  <Label
                    className="cursor_pointer"
                    onClick={() =>
                      this.toggleResourceHealth(resource, index, false)
                    }
                    color={"green"}
                  >
                    Mark as Healthy
                  </Label>
                </p>
              }
            />
          ) : (
            <CircularButton
              onClick={() => this.toggleResourceHealth(resource, index, true)}
              className={`button button--circular margin-right-half `}
              icon="check"
              popupContent={
                <p>
                  Resource status is : {resource?.resource_status || "Unknown"}
                  <br />
                  Click to{" "}
                  <Label
                    className="cursor_pointer"
                    onClick={() =>
                      this.toggleResourceHealth(resource, index, true)
                    }
                    color={"red"}
                  >
                    Mark as Unhealthy
                  </Label>
                </p>
              }
            />
          )}

          <CircularButton
            onClick={() => this.getMetaData(resource, index)}
            className={`button button--circular margin-right-half `}
            icon="file alternate outline"
            popupContent="Fetch Resource MetaData"
          />
        </React.Fragment>
      );
    }
  };

  renderRow = (resource, index) => {
    return {
      status: getIconForResource(resource.resource_status),
      resource_type: (
        <QuickView>
          <QuickView.Trigger>{resource.resource_type}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`Type: ${resource.resource_type}`}</QuickView.Item>
            <QuickView.Copy copy={resource.resource_type}>
              Copy Resource Type
            </QuickView.Copy>
            <QuickView.Copy copy={resource.resource_name}>
              Copy Resource Name
            </QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ),
      resource_name: (
        <QuickView>
          <QuickView.Trigger>{resource.resource_name}</QuickView.Trigger>
          <QuickView.Content>
            <QuickView.Item>{`Name: ${resource.resource_name}`}</QuickView.Item>
            <QuickView.Copy copy={resource.resource_type}>
              Copy Resource Type
            </QuickView.Copy>
            <QuickView.Copy copy={resource.resource_name}>
              Copy Resource Name
            </QuickView.Copy>
          </QuickView.Content>
        </QuickView>
      ),
      action: this.renderActions(resource, index),
    };
  };

  render() {
    const { data, modalStatus, activeIndex, metaData } = this.state;

    const columns = createTableHeaderObject(
      "__Hidden__",
      filterActionColumn(
        defaultValues.orchestration.columns,
        this.props.hasCRUDAccess,
      ),
      ["", "", " padding-left"],
    );

    if (data === undefined) {
      return (
        <Tab.Pane>
          <div className="loader-wrapper">
            <Loader size="mini" active className="one-liner">
              Fetching resources...
            </Loader>
          </div>
        </Tab.Pane>
      );
    }

    if (data === null || !data?.length) {
      return <Tab.Pane className="padding-top-30">No data found!</Tab.Pane>;
    }

    return (
      <Tab.Pane className="">
        <TableWrapper
          data={data.map((x, i) => this.renderRow(x, i))}
          columns={columns}
        />
        <Modal
          open={modalStatus}
          centered={true}
          onClose={() => this.toggleModal()}
        >
          <Header icon="file alternate outline" content={"Resource MetaData"} />
          <Modal.Content>
            <p>Resource Type : {data?.[activeIndex]?.resource_type}</p>
            <p>Resource Name : {data?.[activeIndex]?.resource_name}</p>

            <p>MetaData</p>
            <TextArea rows={6} value={JSON.stringify(metaData, undefined, 2)} />
          </Modal.Content>
          <Modal.Actions>
            <button
              className="float-left button button--bordered"
              onClick={() => this.toggleModal()}
            >
              <span>Back</span>
            </button>

            <button
              className="float-right button button--blue button--icon__left"
              onClick={() =>
                copyToClipboard(JSON.stringify(metaData, undefined, 2))
              }
            >
              <Icon name="copy" />
              <span>Copy MetaData!</span>
            </button>
          </Modal.Actions>
        </Modal>
      </Tab.Pane>
    );
  }
}

export default Resources;
